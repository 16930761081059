import { Button, ButtonTargetKind, ButtonRole } from "@components/Button";
import { Card } from "@components/Card";
import { BackgroundProvider } from "@components/Home/LandingPage/BackgroundProvider";
import { LandingFeed } from "@components/Home/LandingPage/LandingFeed";
import {
  LandingSeeRecentDonationsButton,
  LandingSeeRecentDonationsButtonsSection,
  defaultPageLayoutCss,
  defaultPageLayoutFooterCss,
  landingFeedPageSectionCss,
  pageSectionCss,
} from "@components/Home/LandingPage/styles";
import { Icon, IconSize, IconDisplay } from "@components/Icon";
import { PivotalBanner } from "@components/PivotalBanner";
import { DefaultPageLayout } from "@components/layout/DefaultPageLayout";
import { PageSection } from "@components/layout/PageSection";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { NextPage } from "next";
import { useRef } from "react";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";
import { FeedItemType } from "@every.org/common/src/entity/types";
import { LoggedOutAATest } from "@every.org/common/src/helpers/abtesting/LoggedOutAATest";
import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";
import { assertEnvPresent } from "@every.org/common/src/helpers/getEnv";

import { ReactComponent as NonprofitImage } from "src/assets/illustrations/nonprofit_avatar_animation.svg";
import { ReactComponent as UserImage } from "src/assets/illustrations/user_avatar_animation.svg";
import { LoadImageWithPriorityProvider } from "src/context/LoadImageWithPriorityContext/LoadImageWithPriorityContext";
import { EdoTheme } from "src/context/ThemeContext";
import { colorCssVars } from "src/theme/color";
import {
  minWidthForMediaSize,
  MediaSize,
  cssForMediaSize,
} from "src/theme/mediaQueries";
import {
  horizontalStackCss,
  spacing,
  verticalStackCss,
} from "src/theme/spacing";
import { FontWeight, textSizeCss } from "src/theme/text";
import { useABTestTrack, useStatSigLayer } from "src/utility/abtesting";
import { getWindow } from "src/utility/window";

//#region Styles
const HeroText = styled.div`
  text-align: center;

  h1 {
    padding-top: ${spacing.m};
    line-height: ${48 / 16}rem;
    font-size: ${44 / 16}rem;
  }

  h2 {
    ${textSizeCss.m};
    color: var(${colorCssVars.emphasis});
    margin-top: ${spacing.xs};
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      h1 {
        padding-top: 0;
      }
      h2 {
        ${textSizeCss.l};
        line-height: ${28 / 16}rem;
      }
    `,
  })}
`;

const HeroContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: ${spacing.xxl};
  align-items: center;
  padding: 24px;

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      padding: 48px 48px 64px;
      max-width: 670px;
      width: 100%;
    `,
  })}
`;

const HeroButtonsContainer = styled.div`
  ${verticalStackCss.xs};
`;

const StyledHeroButton = styled(Button)`
  display: flex;
  padding: calc(${spacing.m} - 2px);
  border: 2px solid var(${colorCssVars.accent.large});
  border-radius: 16px;
  max-width: 450px;
  background-color: var(${colorCssVars.background.normal});
  transition: border-color 0.3s ease-in, box-shadow 0.3s ease-in;

  &:hover {
    border: 2px solid var(${colorCssVars.accent.largeHighlight});
    box-shadow: 0px 8px 32px -16px rgba(46, 52, 52, 0.2);

    .heroButton-arrow {
      transform: translateX(8px);
    }

    .heroButton-title {
      color: var(${colorCssVars.accent.largeHighlight});
    }
  }

  .heroButton-content {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr auto;
    align-items: center;
    grid-row-gap: ${spacing.xs};
  }

  .heroButton-text {
    display: flex;
    flex-direction: column;
    gap: ${spacing.xs};
    grid-column: 1 / 2;
  }

  .heroButton-title,
  .heroButton-title-small {
    color: var(${colorCssVars.accent.large});
    ${horizontalStackCss.s};
    align-items: center;
  }

  .heroButton-title {
    ${textSizeCss.m};
    display: none;
  }

  .heroButton-title-small {
    ${textSizeCss.s};
    display: block;
  }

  .heroButton-description {
    ${textSizeCss.xs};
    font-weight: ${FontWeight.MEDIUM};
  }

  .heroButton-arrow {
    flex-shrink: 0;
    transition: transform 0.3s ease-in;
    margin-left: auto;
    grid-row: 1 / 3;
    grid-column: 3 / 4;
  }

  .heroButton-avatar {
    ${horizontalStackCss.xs};
    align-items: center;

    & > svg {
      display: flex;
      width: 32px;
      height: 32px;
    }

    grid-column: 1 / 2;
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      border-radius: 500px;
      padding-right: ${spacing.xl};

      .heroButton-content {
        grid-template-columns: auto 1fr auto;
        grid-row-gap: 0;
        grid-column-gap: ${spacing.xs};
      }

      .heroButton-title {
        display: block;
      }

      .heroButton-title-small {
        display: none;
      }

      .heroButton-text {
        grid-column: 2 / 3;
        gap: ${spacing.xxs};
      }

      .heroButton-description {
        ${textSizeCss.s}
      }

      .heroButton-avatar {
        grid-column: 1 / 2;
        & > svg {
          width: 88px;
          height: 88px;
        }
      }
    `,
  })}
`;

const cssForShortScreen = ({ bannerHeight }: { bannerHeight?: number }) => [
  css`
    min-height: calc(570px + ${bannerHeight ?? 0}px);
  `,
  cssForMediaSize({
    min: MediaSize.X_LARGE,
    css: css`
      @media (max-height: 760px) {
        min-height: calc(470px + ${bannerHeight ?? 0}px);
        ${HeroContentCard} {
          flex-direction: row;
          justify-content: space-between;
          max-width: 900px;
          padding: 48px;
        }

        ${HeroText} {
          width: 290px;
          flex-shrink: 0;
          text-align: left;
        }
        ${HeroButtonsContainer} {
          margin-left: auto;
        }
      }
    `,
  }),
];

//#endregion

//#region HeroButton
interface HeroButtonProps {
  title: string;
  description: string;
  avatar: React.ReactNode;
  to: string;
  "data-tname": string;
}

const HeroButton = ({
  title,
  description,
  avatar,
  to,
  "data-tname": dataTname,
}: HeroButtonProps) => {
  const avatarContainerRef = useRef<HTMLDivElement>(null);

  const triggerAnimation = (direction: "forwards" | "backwards") => {
    if (avatarContainerRef.current) {
      const animations = avatarContainerRef.current.querySelectorAll("animate");
      animations.forEach((animation) => {
        if (animation.classList.contains(direction)) {
          animation.setAttribute("repeatCount", "1");
          animation.beginElement();
        }
      });
    }
  };

  const handleMouseEnter = () => triggerAnimation("forwards");
  const handleMouseLeave = () => triggerAnimation("backwards");

  return (
    <StyledHeroButton
      className="heroButton"
      contentClassName="heroButton-content"
      role={ButtonRole.UNSTYLED}
      onClick={{
        kind: ButtonTargetKind.LINK,
        to,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-tname={dataTname}
    >
      <div ref={avatarContainerRef} className="heroButton-avatar">
        {avatar}
        <h3 className="heroButton-title-small">{title}</h3>
      </div>
      <div className="heroButton-text">
        <h3 className="heroButton-title">{title}</h3>
        <p className="heroButton-description">{description}</p>
      </div>
      <Icon
        className="heroButton-arrow"
        iconImport={() => import("@components/Icon/icons/ArrowForwardIcon")}
        size={IconSize.MEDIUM}
        display={IconDisplay.ACCENT}
      />
    </StyledHeroButton>
  );
};
//#endregion

//#region LandingPage
interface LandingPageProps {
  feedItems?: FeedItemResponse[];
}

export const LandingPage: NextPage<LandingPageProps> = ({ feedItems }) => {
  // A/A test, don't actually do anything but split users into different variants
  // Metrics should not differ between the two
  useABTestTrack(LoggedOutAATest);

  const statsigLayer = useStatSigLayer("new_landing_page_layer");

  const nonprofitCtaFirst = statsigLayer.get<boolean>("np_cta_first", false);
  const heading = statsigLayer.get<string>("heading", "");
  const subHeading = statsigLayer.get<string>("sub_heading", "");
  const npButtonHeading = statsigLayer.get<string>("np_button_heading", "");
  const npButtonCopy = statsigLayer.get<string>("np_button_copy", "");
  const donButtonHeading = statsigLayer.get<string>("don_button_heading", "");
  const donButtonCopy = statsigLayer.get<string>("don_button_copy", "");

  const forNonprofitsButton = (
    <HeroButton
      data-tname="for-nonprofits"
      title={npButtonHeading}
      description={npButtonCopy}
      avatar={<NonprofitImage />}
      to={getRoutePath({
        name: ClientRouteName.NONPROFITS_LANDING,
        format: URLFormat.RELATIVE,
      })}
    />
  );

  const forDonorsButton = (
    <HeroButton
      data-tname="for-donors"
      title={donButtonHeading}
      description={donButtonCopy}
      avatar={<UserImage />}
      to={getRoutePath({
        name: ClientRouteName.SIGNUP,
        format: URLFormat.RELATIVE,
      })}
    />
  );

  const buttonsInOrder = nonprofitCtaFirst
    ? [forNonprofitsButton, forDonorsButton]
    : [forDonorsButton, forNonprofitsButton];

  const priorityItems =
    feedItems
      ?.slice(0, 4)
      .map((item) =>
        item.type === FeedItemType.USER_DONATION
          ? (item.donationCharge.toNonprofitId as string)
          : ""
      ) || [];

  const scrollToRecentDonations = () => {
    const window = getWindow();
    if (window) {
      window.scrollTo({
        top:
          window.innerHeight -
          (window.innerWidth < minWidthForMediaSize[MediaSize.MEDIUM] ? 0 : 75),
        behavior: "smooth",
      });
    }
  };

  const showPivotalBanner = useStatSigLayer("banner").get<boolean>(
    "show",
    false
  );

  return (
    <LoadImageWithPriorityProvider initialValue={{ ids: priorityItems }}>
      <DefaultPageLayout
        themeOverride={EdoTheme.DARK}
        css={defaultPageLayoutCss}
        footerCss={defaultPageLayoutFooterCss}
        pageTitle={
          "Every.org: Online Fundraising Platform Built for Nonprofits and Donors"
        }
        metas={{
          "og:description":
            "Every.org is a tech-for-good nonprofit that simplifies online giving for nonprofits and donors.",
        }}
        // This page (/index-public) is served at / via rewrite
        canonical={
          assertEnvPresent(
            process.env.NEXT_PUBLIC_WEBSITE_ORIGIN,
            "WEBSITE_ORIGIN"
          ) + "/"
        }
      >
        <BackgroundProvider
          css={[
            cssForShortScreen({ bannerHeight: showPivotalBanner ? 100 : 0 }),
          ]}
        >
          {showPivotalBanner && <PivotalBanner />}
          <PageSection
            css={{ flexBasis: "100%" }}
            contentCss={[pageSectionCss]}
          >
            <HeroContentCard>
              <HeroText>
                <h1>{heading}</h1>
                <h2>{subHeading}</h2>
              </HeroText>
              <HeroButtonsContainer>{buttonsInOrder}</HeroButtonsContainer>
            </HeroContentCard>
            <LandingSeeRecentDonationsButtonsSection>
              <LandingSeeRecentDonationsButton
                data-tname="LandingPage-ShowRecentDonations-Button"
                role={ButtonRole.PRIMARY}
                contentCss={[
                  horizontalStackCss.xs,
                  { alignItems: "center", justifyContent: "center" },
                ]}
                onClick={{
                  kind: ButtonTargetKind.FUNCTION,
                  action: scrollToRecentDonations,
                }}
              >
                <span>See recent donations</span>
                <Icon
                  iconImport={() =>
                    import("@components/Icon/icons/SelectArrowIcon")
                  }
                  size={IconSize.SMALL}
                  display={IconDisplay.CURRENT_COLOR}
                />
              </LandingSeeRecentDonationsButton>
            </LandingSeeRecentDonationsButtonsSection>
          </PageSection>
        </BackgroundProvider>
        <PageSection contentCss={landingFeedPageSectionCss}>
          <LandingFeed initialItems={feedItems} />
        </PageSection>
      </DefaultPageLayout>
    </LoadImageWithPriorityProvider>
  );
};
//#endregion
