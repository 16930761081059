import { LandingPage as OldLandingPage } from "@components/Home/LandingPage";
import { LandingPage as NewLandingPage } from "@components/Home/NewLandingPage";
import { NextPage } from "next";

import { FeedItemResponse } from "@every.org/common/src/codecs/entities";

import { useStatSigLayer } from "src/utility/abtesting";

type LandingPageProps = {
  feedItems?: FeedItemResponse[];
};

export const LandingPage: NextPage<LandingPageProps> = (props) => {
  const showNewLandingPage = useStatSigLayer(
    "new_landing_page_layer"
  ).get<boolean>("show_new_design", false);

  if (showNewLandingPage) {
    return <NewLandingPage {...props} />;
  }

  return <OldLandingPage {...props} />;
};
