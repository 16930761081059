import { Button, ButtonRole, ButtonTargetKind } from "@components/Button";
import {
  LandingNonprofitsTabContainer,
  LandingTabContainer,
} from "@components/Home/LandingPage/LandingContent/styles";
import { LandingSignup } from "@components/Home/LandingPage/LandingSignup";
import { LandingUserTypeTab } from "@components/Home/LandingPage/types";
import { useExperiment } from "@statsig/react-bindings";
import React from "react";

import {
  getRoutePath,
  ClientRouteName,
  URLFormat,
} from "@every.org/common/src/helpers/clientRoutes";

const NewLineText = ({ text }: { text: string }) => (
  <React.Fragment>
    {text
      .replace(/\\n/g, "\n")
      .split("\n")
      .map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
  </React.Fragment>
);

const LandingDonorsTab = () => {
  const h1 =
    useExperiment("landing_donor_tab_copy")?.get<string>("h1") ??
    "Easier giving.\nGreater good.";
  const h2 =
    useExperiment("landing_donor_tab_copy")?.get<string>("h2") ??
    "Built by a nonprofit.";

  return (
    <LandingTabContainer>
      <h1>
        <NewLineText text={h1} />
      </h1>
      <h2>{h2}</h2>
      <LandingSignup />
    </LandingTabContainer>
  );
};

const LandingNonprofitsTab = () => {
  const h1 =
    useExperiment("landing_nonprofit_tab_copy")?.get<string>("h1") ??
    "Accept cash, stock, crypto, and DAF gifts.";

  const h2 =
    useExperiment("landing_nonprofit_tab_copy")?.get<string>("h2") ??
    "Fundraise more with an easy fundraising platform built by a nonprofit.";

  return (
    <LandingNonprofitsTabContainer>
      <h1>
        <NewLineText text={h1} />
      </h1>
      <h2>
        <NewLineText text={h2} />
      </h2>
      <Button
        data-tname="LandingNonprofitTab-learnMore"
        role={ButtonRole.PRIMARY}
        onClick={{
          kind: ButtonTargetKind.LINK,
          to: getRoutePath({
            name: ClientRouteName.NONPROFITS_LANDING,
            format: URLFormat.ABSOLUTE,
          }),
        }}
      >
        Learn more
      </Button>
    </LandingNonprofitsTabContainer>
  );
};

export function LandingContent({
  selectedTab,
}: {
  selectedTab: LandingUserTypeTab;
}) {
  switch (selectedTab) {
    case LandingUserTypeTab.DONORS:
      return <LandingDonorsTab />;
    case LandingUserTypeTab.NONPROFITS:
      return <LandingNonprofitsTab />;
  }
}
